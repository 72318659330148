import React from 'react';
import './Footer.css';
import Resume from '../rout_resume.pdf'
import useIsMobile from '../useIsMobile';

const Footer = () => {
    const isMobile = useIsMobile()
    const linkedInIcon = <svg style={{ marginBottom: '-2px', marginLeft: '-2px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
    <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
    </svg>
    const emailIcon = <svg style={{ marginBottom: '-2px', marginLeft: '-2px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope" viewBox="0 0 16 16">
    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
    </svg>
    const pdfIcon = <svg style={{ marginBottom: '-2px', marginLeft: '-2px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/>
    </svg>
    return (
        <footer className="footer" id="footer">
            <div className="footer-left">
                Emma Rout - 2024
            </div>
            <div className="footer-right">
            <a className="navbar-link" href="https://www.linkedin.com/in/emma-rout-684645230/" target="_blank" rel="noreferrer">
                    {isMobile ? (
                        <>
                            {linkedInIcon}
                        </>
                    ) : (
                        <>
                            LinkedIn {linkedInIcon}
                        </>
                    )}
                </a>
            <a className="navbar-link" href="mailto:emma*inem@gmail.com">
                {isMobile ? (
                        <>
                            {emailIcon}
                        </>
                    ) : (
                        <>
                            Email {emailIcon}
                        </>
                    )}
            </a>
                <a className="navbar-link" href={Resume} target="_blank" rel="noopener noreferrer">
                    {isMobile ? (
                        <>
                            {pdfIcon}
                        </>
                    ) : (
                        <>
                            Resume {pdfIcon}
                        </>
                    )}
                </a>
            </div>
        </footer>
    );
};

export default Footer;
